import * as React from "react";
import { CacheProvider } from "@emotion/core";
import createCache from "@emotion/cache";
import asciiDoge from "./src/utils/asciiDoge";

export const wrapRootElement = ({ element }) => {
  const cache = createCache();

  return <CacheProvider value={cache}>{element}</CacheProvider>;
};

export const onClientEntry = () => {
  const externalScript = document.createElement("script");
  externalScript.async = true;
  externalScript.defer = true;
  externalScript.src =
    "https://www.googletagmanager.com/gtag/js?id=G-M814S9VPYN";
  document.querySelector("#___gatsby").appendChild(externalScript);
  const windowScript = document.createElement("script");
  windowScript.async = true;
  windowScript.defer = true;
  windowScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-M814S9VPYN');
  `;
  document.querySelector("#___gatsby").appendChild(windowScript);

  console.log(asciiDoge());
};
