import { alpha } from "@theme-ui/color";

export default {
  breakpoints: ["768px", "1024px", "1440px"],
  colors: {
    text: "white",
    muted: "#9E9CA2",
    background: "#0C0717",
    primary: "#7026B9",
    secondary: "#f219aa",
    vivid: "#A45DE9",
    vividLighter: "#E3CEF8",
    white: "#FFFFFF",
    magenta: "#D459AB",
    magentaLighter: "#F2C4E3",
    magentaBackground: "#1E0621",
    purple: "#7026B9",
    purpleBackground: "#160A27",
    yellow: {
      60: "#FEC21E",
      80: "#BF9141",
    },
    hover: {
      magenta: "#F2C4E3",
      purple: "#E3CEF8",
    },
    talk: {
      primary: "#A45DE9",
      background: "#160A27",
    },
    workshop: {
      primary: "#D459AB",
      background: "#1E0621",
    },
  },
  fonts: {
    body: "Inter, system-ui, sans-serif",
    heading: "Inter, system-ui, sans-serif",
    monospace: `Menlo, ui-monospace, SFMono-Regular, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
  },
  fontWeights: {
    body: 400,
    heading: 800,
    bold: 700,
  },
  layout: {
    main: {
      maxWidth: 1174,
      mx: "auto",
    },
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  radii: [0, 2, 4, 8, 12, 24, 32, 48, 64, 72, 9999],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  text: {
    default: {
      fontFamily: `body`,
      color: `text`,
    },
    link: {
      fontFamily: `body`,
      color: `muted`,
      textDecoration: `none`,
      "&:hover": {
        color: `magenta`,
        textDecoration: `underline`,
      },
    },
    heading: {
      fontFamily: `heading`,
      fontWeight: `heading`,
    },
    gradient: {
      fontFamily: `body`,
      background: (theme) =>
        `linear-gradient(to right, ${theme.colors.secondary}, ${theme.colors.primary})`,
      WebkitBackgroundClip: `text`,
      WebkitTextFillColor: `transparent`,
    },
    capitalized: {
      fontWeight: `bold`,
      textTransform: `uppercase`,
      letterSpacing: `0.08em`,
    },
    talks: {
      color: `vivid`,
    },
    screenReader: {
      position: `absolute`,
      width: 1,
      height: 1,
      padding: 0,
      margin: -1,
      overflow: `hidden`,
      clip: `rect(0, 0, 0, 0)`,
      whiteSpace: `nowrap`,
      borderWidth: 0,
    },
  },
  badges: {
    primary: {
      background: `transparent`,
      border: (theme) => `1px solid ${theme.colors.magenta}`,
      borderRadius: 9999,
      px: `3`,
      py: `1`,
      textTransform: `uppercase`,
      fontWeight: `body`,
      letterSpacing: `0.08em`,
    },
  },
  buttons: {
    primary: {
      fontSize: `3`,
      fontWeight: `bold`,
      px: `4`,
      py: `3`,
      borderRadius: 9999,
      background: (theme) =>
        `linear-gradient(to right, ${theme.colors.secondary}, ${theme.colors.primary})`,
      cursor: `pointer`,
      "&:focus": {
        borderColor: `secondary`,
        boxShadow: (t) => `0 0 0 3px ${t.colors.secondary}`,
        outline: `none`,
      },
    },
    inactive: {
      fontSize: `3`,
      fontWeight: `bold`,
      px: `4`,
      py: `3`,
      borderRadius: 9999,
      backgroundColor: `#160A27`,
      cursor: `pointer`,
      border: (theme) => `1px solid ${theme.colors.vivid}`,
      color: `vivid`,
    },
    track: {
      fontSize: `3`,
      fontWeight: `bold`,
      px: `4`,
      py: `3`,
      borderRadius: 9999,
      backgroundColor: `#A45DE9`,
      cursor: `pointer`,
      color: `white`,
      "&:focus": {
        outline: `none`,
      },
    },
  },
  grids: {
    schedule: {
      gridTemplateColumns: [
        `none`,
        `1fr 5fr 1fr`,
        `2fr 6fr 4fr`,
        `2fr 7fr 4fr`,
      ],
    },
  },
  forms: {
    label: {
      color: `muted`,
      mb: `2`,
      fontSize: 1,
      fontWeight: "body",
    },
    input: {
      p: `3`,
      fontSize: `3`,
      bg: alpha(`primary`, 0.2),
      borderColor: `transparent`,
      borderRadius: `4`,
      "&::placeholder": {
        color: alpha(`muted`, 0.5),
      },
      "&:valid + div > svg": {
        opacity: 1,
        color: alpha(`secondary`, 0.5),
      },
      "&:focus": {
        borderColor: `secondary`,
        boxShadow: (t) => `0 0 0 2px ${t.colors.secondary}`,
        outline: `none`,
      },
    },
  },
  styles: {
    root: {
      backgroundColor: `background`,
      color: `text`,
    },
    a: {
      color: `vivid`,
    },
    hr: {
      color: `vivid`,
      opacity: 0.25,
    },
  },
};
