const lines = [
  "Help us build the future web! See our open roles at https://www.gatsbyjs.com/careers",
  " ",
  "(Image description- a cute doggo made out of ASCII characters, 20 lines)",
  "         ▄              ▄",
  "        ▌▒█           ▄▀▒▌",
  "        ▌▒▒█        ▄▀▒▒▒▐",
  "       ▐▄▀▒▒▀▀▀▀▄▄▄▀▒▒▒▒▒▐",
  "     ▄▄▀▒░▒▒▒▒▒▒▒▒▒█▒▒▄█▒▐",
  "   ▄▀▒▒▒░░░▒▒▒░░░▒▒▒▀██▀▒▌",
  "  ▐▒▒▒▄▄▒▒▒▒░░░▒▒▒▒▒▒▒▀▄▒▒▌",
  "  ▌░░▌█▀▒▒▒▒▒▄▀█▄▒▒▒▒▒▒▒█▒▐",
  " ▐░░░▒▒▒▒▒▒▒▒▌██▀▒▒░░░▒▒▒▀▄▌            Much Gatsby",
  " ▌░▒▄██▄▒▒▒▒▒▒▒▒▒░░░░░░▒▒▒▒▌                    Very Conf",
  "▌▒▀▐▄█▄█▌▄░▀▒▒░░░░░░░░░░▒▒▒▐",
  "▐▒▒▐▀▐▀▒░▄▄▒▄▒▒▒▒▒▒░▒░▒░▒▒▒▒▌",
  "▐▒▒▒▀▀▄▄▒▒▒▄▒▒▒▒▒▒▒▒░▒░▒░▒▒▐",
  " ▌▒▒▒▒▒▒▀▀▀▒▒▒▒▒▒░▒░▒░▒░▒▒▒▌               Wow: https://www.gatsbyjs.com/careers",
  " ▐▒▒▒▒▒▒▒▒▒▒▒▒▒▒░▒░▒░▒▒▄▒▒▐",
  "  ▀▄▒▒▒▒▒▒▒▒▒▒▒░▒░▒░▒▄▒▒▒▒▌",
  "    ▀▄▒▒▒▒▒▒▒▒▒▒▄▄▄▀▒▒▒▒▄▀",
  "      ▀▄▄▄▄▄▄▀▀▀▒▒▒▒▒▄▄▀",
  "         ▒▒▒▒▒▒▒▒▒▒▀▀",
  " ",
];

const asciiDoge = () => {
  return lines.join("\n");
};

export default asciiDoge;
